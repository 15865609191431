import { Box, styled, useTheme } from '@mui/material';
import * as React from 'react';
import './HDSpinner.css';
import { ResponsiveThemeValue } from '../../commons/commonFunctions';

export interface HDSpinnerTypes {
  size: string;
  buttonColor?: boolean;
  color?: string
}

const HDSpinner = (props: HDSpinnerTypes) => {
  const getColor = (props: any, theme: any) => {
    let color;
    if (props.color) {
      color = props.color;
    } else if (props.buttonColor) {
      color = 'inherit';
    } else {
      color = ResponsiveThemeValue(theme, '#18768C','#12ABAB');
    }

    return color;
  };

  const StyledBox = styled('div')(({ theme }) => ({
    color:getColor(props,theme)
  }));

  return (
    <StyledBox className={`la-ball-spin ${props.size} `}>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
      <Box></Box>
    </StyledBox>
  );
};
export default HDSpinner;
