import * as React from 'react';
import { Box, Divider, Stepper, Step, StepLabel, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';

export interface HDStepperBasicProps {
  orientation: 'horizontal' | 'vertical';
  activeStep: number;
  alternativeLabel: boolean;
  sx: object;
}

export interface HDStepperProps {
  steps: Array<any>;
  activeStep: number;
  size?: number;
}

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: '#18768C',
    //marginLeft: 7,
    ...(ownerState.active && {
      color: '#66FCFC',
    }),
    ...(ownerState.completed && {
      color: theme.palette.mode === 'dark' ? '#FFF' : '#002136',
    }),
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

function HDStepper({ steps, activeStep = 1 }: HDStepperProps): any {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const orientation = isMobile ? 'horizontal' : 'vertical';


  const dividerSX =
    orientation === 'horizontal'
      ? {
        height: 30,
        width: '100%',
        marginLeft: 0,
        position: 'absolute',
        right: '-15px',
        top: '-6px'
      }
      : {
        height: '100%',
        width: 13.5,
        position: 'absolute',
        zIndex: 1,
        top: '20px'
      };

  return (
    <Box>
      <Divider orientation={orientation} sx={dividerSX} flexItem />
      <Stepper
        activeStep={activeStep}
        orientation={orientation}
        connector={null}
        sx={{
          //position: 'absolute',
          //left: orientation === 'horizontal' ? 0 : 16,
          top: orientation === 'horizontal' ? 17 : 0,
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label} sx={{ paddingLeft: (orientation === 'horizontal'  || activeStep === index) ? 0 : '7px', paddingRight: '30px' }}>
            {activeStep === index ? (
              <StepLabel
                sx={{
                  '.Mui-active': {
                    color: '#66FCFC !important',
                    width: 26,
                    height: 26,
                    // zIndex: '9'
                  },
                  '.MuiStepIcon-text': {
                    fontSize: 16,
                    fill: '#0B0C10',
                    transform: 'translate(0px , 1px )'
                  },
                  padding: orientation === 'horizontal' ? '10px 0 10px 0' : '10px 0 60px 0',
                  zIndex: '9',
                  position: 'relative'
                }}
              />
            ) : (
              <StepLabel
                sx={{
                  padding: orientation === 'horizontal' ? '0' : '10px 0 60px 0',
                  zIndex: '9',
                  position: 'relative'
                }}
                StepIconComponent={QontoStepIcon}
              />
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default HDStepper;
