import { Box, Dialog, Slide, useMediaQuery, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

export interface FSModalTypes {
  openFSModal: boolean;
  children?: any;
  handleFSModal: (arg0: any) => void;
  footer?: any;
  modalRef: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const FullScreenModal = (props: FSModalTypes) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dialogStyle = {
    width: isMobile ? '100%' : '60%',
    left: 'auto !important',
    border: 'none',
    '& .MuiPaper-root': {
      border: 'none',
      padding: '0',
    },
  };
  const StyledChildrenBox = { padding: isMobile ? '32px 24px' : '64px 32px 64px 32px' };

  return (
    <Dialog
      open={props.openFSModal}
      onClose={props.handleFSModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      fullScreen={true}
      sx={dialogStyle}
      ref={props.modalRef}
    >
      <Box>
        <Box sx={StyledChildrenBox}>{props.children}</Box>
        <Box>{props.footer}</Box>
      </Box>
    </Dialog>
  );
};
