import * as React from 'react';
import {
  Box,
  Radio,
  FormControlLabel,
  styled,
  useTheme,
} from '@mui/material';
import { ResponsiveThemeValue } from '../../commons/commonFunctions';

export interface RadioTypes {
  checked: boolean;
  classes?: any;
  name: string;
  value?: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  showBackground: boolean;
  id?: string;
}

export interface HDRadioTypes extends RadioTypes {
  label: string;
  hasError: boolean;
}

export const RadioButton = styled(({ ...props }: RadioTypes) => (
  <Radio {...props} />
));

export const HDRadio = ({ label, hasError = false, checked, ...restProps }: HDRadioTypes) => {
  const theme = useTheme();

  const StyledBoxWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '10px 0px 10px 20px',
    background: ResponsiveThemeValue(theme , '#f8f8f8' , '#002136'),
    border: '2px solid ' + ((hasError) ? (ResponsiveThemeValue(theme , '#C02195' , '#E449BD')) : 'transparent'),
    borderRadius: '8px',
    ':hover': {
      border: ((!restProps.disabled) ? ResponsiveThemeValue(theme ,'2px solid #18768C' , '2px solid #197e95') : '2px solid transparent'),
      '.MuiRadio-root > span > svg:first-child': {
        color: (!restProps.disabled) ? '#18768C' : '',
      },
      '.MuiRadio-root > span > svg:last-child': {
        color: (!restProps.disabled) ? (ResponsiveThemeValue(theme ,  '#002136' , '#fff')) : '',
      }
    }
  }));

  const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    color: ResponsiveThemeValue(theme , '#002136' ,'#fff'),
    '.MuiFormControlLabel-label': {
      fontWeight: '400',
    }
  }));

  const lightRadio = styled(Radio)(() => ({
    marginBottom: '3px',
    '&.Mui-checked': {
      color: hasError ? '#c02195' : '#002136',
    },
    ':hover': {
      '&.MuiRadio-root > span > svg:first-child': {
        color: '#18768C',
      },
      '&.MuiRadio-root > span > svg:last-child': {
        color: '#002136',
      }
    },
    '&.Mui-disabled': {
      '&.MuiRadio-root > span > svg:first-child': {
        color: '#CCC',
        background: '#CCC',
        borderRadius: '50%',
      },
      '&.MuiRadio-root > span > svg:last-child': {
        color: '#626A7C',
      }
    },
    '&.MuiRadio-root > span > svg:first-child': {
      color: hasError ? '#C02195' : '#646A7C',
    },
    '&.MuiRadio-root > span > svg:last-child': {
      color: '#002136',
    }
  }));

  const darkRadio = styled(Radio)(() => ({
    marginBottom: '3px',
    '&.Mui-checked': {
      color: hasError ? '#e344ba' : '#fff',
    },
    ':hover': {
      '&.MuiRadio-root > span > svg:first-child': {
        color: '#18768C',
      },
      '&.MuiRadio-root > span > svg:last-child': {
        color: '#fff',
      }
    },
    '&.Mui-disabled': {
      '&.MuiRadio-root > span > svg:first-child': {
        color: '#334255',
        background: '#334255',
        borderRadius: '50%',
      },
      '&.MuiRadio-root > span > svg:last-child': {
        color: '#0B0C10',
      }
    },
    '&.MuiRadio-root > span > svg:first-child': {
      color: hasError ? '#E449BD' : '#A9A9A9',
    },
    '&.MuiRadio-root > span > svg:last-child': {
      color: '#fff',
    }
  }));

  const StyledRadio = ResponsiveThemeValue(theme , lightRadio , darkRadio);

  return (
    (restProps.showBackground) ?
      <StyledBoxWrapper>
        <StyledFormControlLabel
          value={label}
          label={label}
          control={
            <StyledRadio
              checked={checked}
              color='primary'
              {...restProps}
            />}
        />
      </StyledBoxWrapper>
      :
      <StyledFormControlLabel
        value={label}
        label={label}
        control={
          <StyledRadio
            checked={checked}
            color='primary'
            {...restProps}
          />}
      />

  );
};
