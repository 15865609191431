import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, CssBaseline, Typography, styled, Container, Divider, Grid, useMediaQuery, useTheme, List, ListItem, ListItemText, Link, Paper } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import HDCard from '../../hd-ui-kit/HDCard';
import HDButton from '../../hd-ui-kit/HDButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Api from './../../Api';
import { useNavigate } from 'react-router';
import FindCoach from './FindCoach';
import CoachBio from './CoachBio';
import { reactGAEvents, getAssesmentStatus, ResponsiveMobileTabletValue, ResponsiveMobileValue, getDynamicCss } from '../../commons/commonFunctions';
import Loader from '../../commons/Loader';
import { globalContext } from '../../store';
import TimeZoneSelectionModal from './TimeZoneSelectionModal';
import HDModal from '../../hd-ui-kit/HDModal';
import HDSpinner from '../../hd-ui-kit/HDSpinner';
import FreeSessionNotifyModal from '../../commons/FreeSessionNotifyModal';
import Carousel from 'react-material-ui-carousel';


const RenderCarousel = ({ParagraphText, isMobile, isTablet, originHost }:any) => {
  const carouselData = [
    {
      bigQuotes: 'Phenomenal. My situation changed significantly since signing up for the program. It has been a valuable investment to accomplish my professional goals.',
      smallQuote: 'Former Director of Organizational Effectiveness, Silicon Valley Bank'
    },
    {
      bigQuotes: 'My coach consistently goes above and beyond, demonstrating unwavering support and firm belief in my potential. Her transparent approach strikes the right balance between guiding and listening.',
      smallQuote: 'Vice President, Talent Management, JM Family Enterprises'
    },
    {
      bigQuotes: 'My coach asks fantastic questions that push me out of my comfort zone and urge me to dig deep about the reason behind my actions, thoughts and feelings.',
      smallQuote: 'Managing Director, Healthcare Industry'
    }
  ];

  const carouselContent = {
    padding: ResponsiveMobileValue(isMobile,'64px 16px' , '64px'),
    display: 'flex',
    backgroundColor: '#197E95',
    borderRadius: ResponsiveMobileValue(isMobile,'0px' , '6px'),
    flexDirection: ResponsiveMobileValue(isMobile,'column' , 'row'),
    alignItems: 'flex-start'
  };

  const bigQuoteStyle = {
    fontFamily: 'Aventa',
    color: '#FFF',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    wordBreak: 'break-word'
  };

  const smallQuoteStyle = {
    marginTop: '20px',
    fontFamily: 'Aventa',
    color: '#FFF',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    wordBreak: 'break-word'
  };

  const height = getDynamicCss( isMobile, '450px', isTablet, '270px', '175px');
  const quoteStyle = {
    flexDirection: ResponsiveMobileValue(isMobile,'column' , 'row'),
  };

  return (
    <Box sx={carouselContent}>
      <Box sx={quoteStyle}>
        <img alt='Carousel Quote' src={originHost + '/res/carouselQuote.svg'} />
      </Box>
      <Box style={{ width: '100%', marginLeft: ResponsiveMobileValue(isMobile,'' , '30px') }}>
        <Carousel
          cycleNavigation={true}
          IndicatorIcon={null}
          indicatorContainerProps={{
            style: {
              marginTop: ResponsiveMobileValue(isMobile,'20px' , '0px'),
              textAlign: 'justify',
              marginLeft: '-5px',

            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundImage: 'url(' + originHost + '/res/carouselActive.svg)',
              width: '18px',
              height: '18px',
              backgroundSize: 'cover',
              margin: '5px',
              cursor: 'pointer',
            },
          }}
          indicatorIconButtonProps={{
            style: {
              backgroundImage: 'url(' + originHost + '/res/carouselNext.svg)',
              width: '18px',
              height: '18px',
              backgroundSize: 'transparent',
              border: 'none',
              margin: '5px',
              cursor: 'pointer',
            },
          }}
          autoPlay={true}
          navButtonsAlwaysInvisible={true}
          navButtonsAlwaysVisible={false}
          stopAutoPlayOnHover={false}
          animation={'slide'}
          swipe={true}
          interval={12000}
          duration={1000}
          changeOnFirstRender={false}
        >
          {
            carouselData.map((carousel, i) => (
              <Paper key={`test3-item-${carousel.smallQuote}`} elevation={10} style={{ height:  height, boxShadow: 'none', backgroundColor: '#197E95', backgroundImage: 'none' }} >
                <ParagraphText sx={bigQuoteStyle}>{carousel.bigQuotes}</ParagraphText>
                <ParagraphText sx={smallQuoteStyle}>{carousel.smallQuote}</ParagraphText>
              </Paper>
            )
            )
          }
        </Carousel>
      </Box>
    </Box>
  );
};

function CoachPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const originHost = window.location.origin;

  const [isSubscribed, setSubscriptionStatus] = useState(false);
  const [, setSubscribeBtnTitle] = useState('');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const [isAssessmentFinished, setAssessmentStatus] = useState<any>(false);
  const [allCoachData, setAllCoachData] = useState<any>([]);
  const [tabsDataCoach, setTabsDataCoach] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState('We are selecting your coaches');
  const [step, setStep] = useState<number>(0);
  const [availabilityAllData, setAvailabilityAllData] = useState({});
  const [availableDates, setAvailableDates] = useState({});
  const [selectedCoachItem, setSelectedCoachItem] = useState({});
  const [errorMsg, setErrorMsg] = useState<any>('');
  const [coachPrice, setCoachPrice] = useState(0);
  const [,setLeaderHasCoach] = useState(true);
  const secondSection = useRef(null);
  const bottomSection = useRef(null);
  const [assessmentNotTaken, setAssessmentNotTaken] = useState(false);
  const [chemistrySessionCompleted, setChemistrySessionCompleted] = useState(false);
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const isMobileTabletValue = isMobile || isTablet;


  const [openFSModal, setOpenFSModal] = useState<any>(false);
  const [openTzModal, setOpenTzModal] = useState<any>(false);
  const coachModalRef = useRef(null);
  const [accountUpdate, setAccountUpdate] = useState(true);
  const [openNofityModal, setOpenNofityModal] = useState(false);
  const { dispatch, globalState } = useContext(globalContext);
  const [openAcknowledgeModal, setOpenAcknowledgeModal] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState(false);
  const [errorText, setErrorText] = useState<string>('')
  const queryParameters = new URLSearchParams(window.location.search);
  const fromEmail = queryParameters.get('fromEmail');

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
  });

  const styledTitle = {
    color: '#FFFFFF',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: ResponsiveMobileValue(isMobile,'31px' , '41px'),
    lineHeight: ResponsiveMobileValue(isMobile,'41px' , '54px'),
    textAlign: 'start',
  };

  const StyledParagraph = styled(Typography)(() => ({
    color: '#E7E7E7',
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'start',
  }));

  const bannerWrapper = {
    background: (isMobile || isTablet || isLarge) ? 'url(' + originHost + '/res/coachBannerBG.svg' + ')' : 'url(' + originHost + '/res/coachBanner.jpg' + ')',
    display: 'flex',
    backgroundSize: 'cover',
    backgroundPosition: ResponsiveMobileTabletValue(isMobileTabletValue, 'unset' ,'right')
  };

  const bannerTxtStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    margin: getDynamicCss(isMobile, '250px 0 60px 0', isTablet, '420px 0 60px 0', '90px 0'),
    width: ResponsiveMobileTabletValue(isMobileTabletValue,'100%' , '50%'),
  };

  const bannerImgStyle = {
    width: '100%',
    backgroundImage: (isMobile || isTablet || isLarge) ? 'url(' + originHost + '/res/coachBannerImgNew.png' + ')' : '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: ResponsiveMobileTabletValue(isMobileTabletValue,'contain' , '54%'),
    backgroundPosition: ResponsiveMobileTabletValue(isMobileTabletValue, 'top' ,'bottom right'),
  };

  const BannerBelowTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: ResponsiveMobileValue(isMobile,'41px' , '64px'),
    lineHeight: ResponsiveMobileValue(isMobile,'54px' , '64px'),
    textAlign: 'start',
    color: '#002136'
  }));

  const ParagraphText = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'start',
    color: '#334255',
    marginTop: ResponsiveMobileValue(isMobile,'20px' , '0')
  }));

  const CardsTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: ResponsiveMobileValue(isMobile,'31px' , '41px'),
    lineHeight: ResponsiveMobileValue(isMobile,'41px' , '54px'),
    textAlign: 'start',
    color: '#002136'
  }));

  const bannerBelowWrapper = {
    display: 'flex',
    flexDirection: ResponsiveMobileValue(isMobile,'column' , 'row'),
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: ResponsiveMobileValue(isMobile,'40px 0' , '80px 0')
  };

  const cardsWrapper = {
    padding: ResponsiveMobileValue(isMobile,'40px 0' , '80px 0')
  };
  const StyledGridContainer = { display: 'flex', marginTop: '15px' };
  const StyledCard = { marginBottom: ResponsiveMobileTabletValue(isMobileTabletValue,'18px' , '') };
  const StyledCardIndex = { marginBottom: ResponsiveMobileTabletValue(isMobileTabletValue,'18px' , ''), marginLeft: isMobile ? '' : '0px' };
  const sxGoalGrid = { display: 'flex' };
  const startedCardStyle = {
    height: '100%',
    background: '#f8f8f8',
    border: '1px solid #DADCDF',
    boxShadow: 'none',
    textAlign: 'left',
    borderBottom: '10px solid #197E95',
    borderRadius: '9px',
    padding: ResponsiveMobileTabletValue(isMobileTabletValue,'24px' , '64px 32px'),
  };

  const CardTitle = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '22px',
    lineHeight: '32px',
    color: '#0B0C10',
  }));

  const CardParagraph = styled(Typography)(() => ({
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#334255',
  }));

  const cardData = [
    {
      title: 'Improve your leadership skills',
      description: 'Lead your team with greater confidence, competence, and impact. Learn how to share your vision and inspire others, while guiding them towards shared objectives.',
    },
    {
      title: 'Plan your next career move',
      description: 'Determine where you want to be, knowing  what got you here, won\'t get you there. Strategize, build a roadmap, expand your network, and prepare for the next step.',
    },
    {
      title: 'Grow your influence',
      description: 'Drive meaningful outcomes by expanding your ability to motivate others, while cultivating trust, respect, and credibility among team members and stakeholders.',
    },
    {
      title: 'Strengthen your emotional intelligence',
      description: 'Understand, manage, and express your emotions more effectively. Uncover insights you need to develop better relationships and make better decisions.',
    },
    {
      title: 'Master storytelling and powerful self-talk',
      description: 'Cultivate your competitive edge by building your personal brand. Learn strategies for positioning your unique attributes, experiences, and achievements.',
    },
    {
      title: 'Navigate high-stakes situations',
      description: 'Learn strategies to stay grounded and drive results in environments where outcomes are unpredictable and landscapes are rapidly shifting.',
    }
  ];

  const bodyContentWrapper = {
    display: 'flex',
    justifyContent: 'space-between',
    // marginTop: '60px',
    flexDirection: ResponsiveMobileValue(isMobile,'column' , 'unset')
  };

  const sliderContentWrapper = {
    width: ResponsiveMobileTabletValue(isMobileTabletValue, '100%' , '50%'),
    textAlign: 'left',
  };

  const ListCount = styled(Box)(() => ({
    background: '#197E95',
    width: '58px',
    height:ResponsiveMobileValue(isMobile,'54px' , '58px'),
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    color: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '32px',
    position: 'relative',
    top: '10px'
  }));

  const listItemStyle = {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'column',
    padding: 0,
    marginBottom: '40px',
  };

  const listTitleStyle = {
    fontFamily: 'Aventa',
    fontWeight: 500,
    fontSize: ResponsiveMobileValue(isMobile,'22px' , '28px'),
    lineHeight: ResponsiveMobileValue(isMobile,'31px' , '40px'),
    color: '#002136',
  };

  const listDescStyle = {
    fontFamily: 'Avenir',
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#334255',
  };

  const dividerStyle = {
    borderColor: 'rgba(100, 106, 124, 0.1)',
    marginTop: '32px',
    marginBottom: '32px'
  };

  const listContent = [
    {
      title: 'Find the right coach',
      description: 'Select one of our carefully-vetted certified executive coaches, handpicked for you based on your experience and goals. Validate the fit during a 30-minute complimentary introduction call*.'
    },
    {
      title: 'Choose your ideal format',
      description: 'Determine how often you would like to meet with your coach, depending on your objectives. We offer either one or two sessions per month, and your subscription will renew monthly until you cancel.'
    },
    {
      title: 'Create a roadmap to achieve your goals',
      description: 'Set clear goals and determine the actions with the greatest value. Structure them into a strategic plan.'
    },
    {
      title: 'Drive meaningful transformation',
      description: 'Meet regularly to track progress, address obstacles, and define next steps. Your coach will provide guidance and resources while holding you accountable for your progress.'
    },
  ];

  const footerData = [
    {
      title: '60-minute coaching sessions',
      description: 'Meet one-on-one at a cadence that works for you and continue as long as you need.'
    },
    {
      title: 'Individualized digital resources',
      description: 'Access personalized resources that your coach recommends based on your unique situation.'
    },
    {
      title: 'Ongoing coach communication',
      description: 'Email or text your coach between sessions as needed to get specific guidance'
    }
  ];

  const footerWrapper = {
    backgroundColor: '#197E95',
  };

  const footerInnerWrap = { padding: '50px 0', textAlign: 'left', marginBottom: '40px' };

  const footerBox = {
    border: '2px solid #fff',
    borderRadius: '6px',
    padding: '20px 80px  20px 20px',
    marginRight: ResponsiveMobileValue(isMobile,'0' , '20px'),
    marginBottom: ResponsiveMobileValue(isMobile,'20px' , '0'),
  };

  const footerPricingBox = {
    border: '2px solid #fff',
    borderRadius: '6px',
    padding: '32px',
    marginRight: ResponsiveMobileValue(isMobile,'0' , '20px'),
    marginBottom: ResponsiveMobileValue(isMobile,'20px' , '0'),
    display: 'flex',
    flexDirection: (isMobile || isTablet || isLarge) ? 'column' : 'row'
  };

  const footerPricingContent = {
    width: getDynamicCss(isMobileTabletValue, '100%', isLarge, '100%', '305px'),
  };

  const footerPricingAction = {
    display: 'flex', alignItems: 'flex-start'
  };

  const getStartedWrapper = {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    marginTop: '20px',
    paddingRight: '65px',
    position: 'relative',
    zIndex: 1,
  };

  const getStartedNote = {
    padding: (isTablet || isLarge) ? '0' : '20px 0',
    textAlign: 'left',
    background: '#fff',
    marginTop: '20px',
    position: 'relative',
    display: 'flex',
    flexDirection: ((isTablet || isLarge)) ? 'column-reverse' : 'row'
  };

  const backgroundImage = (() => {
    if (chemistrySessionCompleted) {
        return 'none';
    }
    return (isMobile || isTablet || isLarge)
        ? 'url(' + originHost + '/res/coachEllipseMob.svg)'
        : 'url(' + originHost + '/res/footerBubble.png)';
  })();

  const bottomImage = {
    backgroundImage:backgroundImage,
    backgroundRepeat: 'no-repeat',
    height: ((isTablet || isLarge) && !isMobile) ? '400px' : ResponsiveMobileValue(isMobile, '293px' ,'490px'),
    width: ((isTablet || isLarge)) ? 'unset' : '40%',
    position: ((isTablet || isLarge)) ? 'relative' : 'absolute',
    right: 0,
    bottom: 0,
    backgroundPosition: ((isTablet || isLarge)) ? 'top' : 'left bottom',
    backgroundSize: 'cover'
  };

  const getStartedWrap = { display: 'flex', flexDirection: 'column', alignSelf: 'flex-end' };
  const bannerBelowTxt = { width: isMobile ? '100%' : '50%' };

  const StyledMainWrapper = styled(Box)(() => ({
    background: 'radial-gradient(100% 200% at 6.02% 0%, #E3ECEF 0%, rgba(241, 241, 241, 0) 40%) #FFF',
  }));

  const btnStyle = {
    background: '#F1F1F1',
    color: '#002136',
    fontFamily: 'Aventa',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '22px',
    marginRight: '9px',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: '#F1F1F1',
    },
    '&:focus': {
      border: '1px solid #F1F1F1 !important;',
      background: '#F1F1F1 !important;',
    },
    '&::before': {
      background: '#F1F1F1 !important;',
      color: '#002136',
    }
  };

  const ArrowIcon = styled(ArrowForwardIcon)(() => ({
    color: '#18768C',
  }));

  const ArrowDownIcon = styled(ArrowDownwardIcon)(() => ({
    color: '#18768C',
  }));

  const footerCardStyle = {
    display: 'flex',
    marginTop: '40px',
    flexDirection: ResponsiveMobileValue(isMobile,'column' , 'row'),
  };

  const footerPlans = {
    marginTop: '20px',
    display: 'flex',
    flexDirection: ResponsiveMobileValue(isMobile,'column' , 'row'),
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 1,
  };

  const paragraphTextStyle = {
    marginTop: '20px'
  };


  useEffect(() => {
    let isAuth = Api.isAuthorized();
    if (!isAuth) {
      if (fromEmail) localStorage.setItem('fromEmailMyCoach', JSON.stringify('True'));
      navigate('/login');
    } else if (accountUpdate) {
      setAccountUpdate(false);
      setCoachPrice(500);
      setSubscribeBtnTitle(`Subscribe now(${formatter.format(500)}/month)`);
    }
  }, [accountUpdate]);


  const handleFSModal = () => {
    step === 0 ? reactGAEvents('coach-select-coach', 'select-coach-close') : reactGAEvents('coach-schedule-session', 'calender-close');
    setOpenFSModal(false);
    setStep(0);
    coachModalRef.current.style.display = 'none';
  };

  const handleTzModalOpen = () => {
    setOpenTzModal(true);
  };

  const handleTzModalClose = () => {
    setOpenTzModal(false);
    setAccountUpdate(true);
  };

  const handleFSModalOpen = (acknowledged = 'no') => {
    if (coachModalRef.current) coachModalRef.current.style.display = 'block';
    if (!globalState?.account?.acknowledge_coach_not_search && acknowledged === 'no') {
      setOpenAcknowledgeModal(true);
    } else {
      setLoading(true);
      setOpenFSModal(true);
      Api.getCoachApi()
        .then((res: any) => {
          let tabsData: any = [];
          if (res.success) {
            const coachData = res?.response?.data?.coach_data.map((item: any, index: number) => {
              tabsData.push({
                label: item.first_name,
                avatar: item.avatar_url,
                coachId: item.coach_id,
              });
              let data = [
                {
                  ...item,
                  imgUrl: isDarkTheme ? originHost + '/res/hd-ui-kit-images/bioIconDark.svg' : originHost + '/res/hd-ui-kit-images/bioIconLight.svg',
                  title: 'Bio',
                  alt: 'Coach bio logo',
                  des: item?.bio,
                },
                {
                  ...item,
                  imgUrl: isDarkTheme ? originHost + '/res/hd-ui-kit-images/expIconDark.svg' : originHost + '/res/hd-ui-kit-images/expIconLight.svg',
                  title: 'Recent Coaching Experience',
                  alt: 'Coaching experience logo',
                  des: item?.recent_coaching_experience,
                },
                {
                  ...item,
                  imgUrl: isDarkTheme ? originHost + '/res/hd-ui-kit-images/qualIconDark.svg' : originHost + '/res/hd-ui-kit-images/qualIconLight.svg',
                  title: 'Qualifications and Background',
                  alt: 'Qualification & Background logo',
                  des: [...item.qualifications, ...item.background],
                },
              ];
              return <CoachBio key={`coachbio_${item.coach_id}`} data={data} coachItem={item} lastName={item.last_name} avatar={item.avatar_url} firstName={item.first_name} nextStep={(data: any) => handleCoachNextStep(data, index)} />;
            });
            setLoading(false);
            if (res?.response?.data?.available_free_chemistry_sessions < 1) {
              const errorMsg = <p>Thank you for your interest in booking your introductory session.<br />Our coaches are in high demand and we do not have any sessions available at this time.<br />We encourage you to check back over the next few weeks when additional sessions open up.</p >;
              setErrorMsg(errorMsg);
              setAllCoachData([]);
              setTabsDataCoach([]);
            } else {
              setAllCoachData(coachData);
              setTabsDataCoach(tabsData);
              setErrorMsg('');
            }
          } else {
            setLoading(false);
            const errorMsg = <p>Thank you for your interest in booking your introductory session.<br />Our coaches are in high demand and we do not have any sessions available at this time.<br />We encourage you to check back over the next few weeks when additional sessions open up.</p>;
            setErrorMsg(errorMsg);
            setAllCoachData([]);
            setTabsDataCoach([]);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleCoachNextStep = (data: any, index: number) => {
    setLoading(true);
    setLoadingMsg('We are fetching all available dates');
    reactGAEvents('coach-select-coach', `select-coach-${index + 1}`);
    const payload = { coach_id: data.coach_id, isSessionList: false, isSubscribed: false };

    Api.getCoachAvailability(payload)
      .then((res: any) => {
        if ((res.success) && (Object.keys(res?.response?.data)?.length > 0)) {
          setAvailableDates(Object.keys(res?.response?.data));
          setAvailabilityAllData(res?.response?.data);
          setSelectedCoachItem(data);
          setStep(1);
          setLoadingMsg('We are selecting your coaches');
          setLoading(false);
          setErrorMsg('');
        } else {
          setLoadingMsg('We are selecting your coaches');
          let link = <a href='https://heidricksupport.zendesk.com/hc/en-us' target='_blank' style={{ color: 'inherit' }}>contact support</a>;
          const errorMsg = <p>There seems to be a problem accessing your coach feature. Please contact support for help. {link}.</p>;
          setErrorMsg(errorMsg);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getSubscriptionStatus = async () => {
    if (Object.keys(globalState?.subscriptionStatus).length != 0) {
      if (globalState?.subscriptionStatus?.status === 'ACTIVE') {
        setSubscriptionStatus(true);
        setSubscribeBtnTitle(`Subscribe now(${formatter.format(coachPrice)}/month)`);
      } else if (globalState?.subscriptionStatus?.status === 'NOT_ACTIVE') {
        setSubscriptionStatus(true);
        setSubscribeBtnTitle('Renew subscription');
        navigate('/profile-edit?active=6');
      } else {
        setSubscriptionStatus(false);
      }
    } else {
      setSubscriptionStatus(false);
    }
  };

  const subscribeNow = async (quantity: number) => {
    try {
      const payload = {
        success_url: window.location.href,
        cancel_url: window.location.href,
        quantity: quantity
      };

      const response = await Api.subscribeStripe(payload);
      if (response.success) {
        const { url } = response.response.data;
        window.open(url, '_self');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const chkUserHasCoach = async () => {
    if (globalState?.sessionsData?.success === false && globalState?.sessionsData != undefined) {
      return; // If there was an error from the API call, return without making the API call again
    }
    let response: any;
    if (globalState?.sessionsData?.response?.data) {
      response = globalState?.sessionsData;
    } else {
      response = await Api.getCoachSessionApi();
      dispatch({ type: 'SET_SESSIONS_DATA', payload: response });
    }
    if (response?.success) {
      if (response?.response?.data?.sessions) {
        const sessionsListLocal = response?.response?.data?.sessions;

        if (sessionsListLocal && Object.keys(sessionsListLocal)?.length > 0) {
          if (sessionsListLocal?.[0]?.['is_completed'] || (new Date(sessionsListLocal?.[0]?.['start_date_hfl_specific']?.replace(/-/g, '/')).getTime() < new Date().getTime())) {
            if (isSubscribed || globalState?.subscriptionStatus?.status === 'ACTIVE') {
              navigate('/my-coach/dashboard');
            }
            setChemistrySessionCompleted(true);
          } else {
            navigate('/my-coach/dashboard');
          }
        }
      } else {
        setLeaderHasCoach(false);
        setChemistrySessionCompleted(false);
      }
    } else {
      setLeaderHasCoach(false);
      setChemistrySessionCompleted(false);
    }
  };

  const getAssessmentStatusData = async () => {
    if (typeof (globalState?.assessmentStatus) == 'undefined' || Object.keys(globalState?.assessmentStatus).length === 0) {
      const assesmentStatusData: any = await getAssesmentStatus();
      dispatch({ type: 'SET_ASSESSMENT_STATUS', payload: assesmentStatusData });
      setAssessmentStatus(assesmentStatusData?.cac === 'yes' && assesmentStatusData?.iac === 'yes');
    } else {
      setAssessmentStatus(globalState?.assessmentStatus.cac === 'yes' && globalState?.assessmentStatus.iac === 'yes');
    }
  };

  useEffect(() => {
    chkUserHasCoach();
    getAssessmentStatusData();
    setAssessmentStatus(globalState?.assessmentStatus?.cac === 'yes' && globalState?.assessmentStatus?.iac === 'yes');
    setPageLoading(false);
    getSubscriptionStatus();
  }, [isAssessmentFinished, globalState?.sessionsData]);

  const buttonAction = () => {
    if (!isSubscribed && !chemistrySessionCompleted) {
      reactGAEvents('coach-select-coach', 'find-coach');
      if (globalState?.account?.available_free_chemistry_sessions < 1) {
        reactGAEvents('coach-select-coach', 'modal-session-wait-list');
        if (!globalState?.account?.acknowledge_coach_not_search) {
          setOpenAcknowledgeModal(true);
        } else {
          setOpenNofityModal(true);
        }
      } else {
        if (!globalState?.account?.iana_timezone || (globalState?.account?.experience?.industry && globalState?.account?.experience?.industry === 'Prefer not to respond')) {
          handleTzModalOpen();
        }
        else if (globalState?.account?.iana_timezone && globalState?.account?.experience?.industry && globalState?.account?.experience?.industry !== 'Prefer not to respond') {
          handleFSModalOpen();
        }
      }
    } else {
      if (chemistrySessionCompleted) {
        const action = coachPrice === 500 ? 'coach-subscribe-now-A' : 'coach-subscribe-now-B';
        reactGAEvents('coach-select-coach', action);
      }
    }

  };

  const acknowledgeCoach = async () => {
    setIsAgree(true);
    try {
      const payload = {
        acknowledge_coach_not_search: true,
      };
      const response = await Api.acknowledgeCoachSearch(payload);
      if (response.success) {
        setErrorText('');
        setIsAgree(false);
        setOpenAcknowledgeModal(false);
        const accountUpdate = {
          ...globalState?.account,
          acknowledge_coach_not_search: true,
        };
        dispatch({ type: 'SET_ACCOUNT', payload: accountUpdate });
        if (globalState?.account?.available_free_chemistry_sessions < 1) {
          setOpenNofityModal(true);
        } else {
          handleFSModalOpen('yes');
        }
      } else {
        setIsAgree(false);
        setOpenAcknowledgeModal(true);
        setErrorText('We are having trouble, Please try again later.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const scrollBelow = () => {
    bottomSection?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  return (
    <>
      {pageLoading && <Loader showNote={true} />}
      <CssBaseline />
      <Header />
      <Box>
        <Box sx={bannerWrapper}>
          <Box sx={bannerImgStyle}>
            <Container maxWidth='lg'>
              <Box sx={bannerTxtStyle}>
                <Typography sx={styledTitle} variant='h1'>Unlock your leadership potential with coaching</Typography>
                <StyledParagraph variant='h5'>Work one-on-one with an executive coach to achieve your career goals and maximize your impact.</StyledParagraph>
                <HDButton
                  text={!chemistrySessionCompleted ? 'Schedule your free intro call' : 'Choose a plan to subscribe'}
                  variant={'contained'}
                  size={'medium'}
                  sx={{ ...btnStyle, marginTop: '20px', width: 'fit-content' }}
                  endIcon={<ArrowDownIcon />}
                  onClick={scrollBelow}
                // disabled={(showMessage && messageType === 'err') ? true : false}
                />
              </Box>
            </Container>
          </Box>
        </Box>

        <StyledMainWrapper ref={secondSection}>
          <Container maxWidth='lg'>
            <Box sx={bannerBelowWrapper}>
              <Box sx={bannerBelowTxt}>
                <BannerBelowTitle variant='h2'>Become the leader you were meant to be</BannerBelowTitle>
              </Box>
              <Box sx={bannerBelowTxt}>
                <ParagraphText variant='h5' sx={{ color: '#0B0C10' }}>Through decades of experience serving the world's top organizations, we know what makes leaders successful—and we have created an executive coaching program to share our knowledge and empower leaders like you.<br /><br />
                  By meeting one-on-one with a certified executive coach for several months, you will discover strategies to reach your goals, navigate challenges and accelerate your professional growth.
                </ParagraphText>
              </Box>
            </Box>
            <Divider sx={{ borderColor: 'rgba(100, 106, 124, 0.2)' }} />

            <Box sx={cardsWrapper}>
              <CardsTitle variant='h2'>Where do you see yourself headed? </CardsTitle>
              <ParagraphText variant='h5'>Through active listening and powerful questioning, your coach will help you become crystal clear on what you want to achieve—and what matters most to you.</ParagraphText>

              <Box>
                <Grid container spacing={2} sx={StyledGridContainer}>
                  {cardData.map((item: any, index: any) => {
                    return (
                      <Grid item lg={4} sm={4} xs={12} key={`card-${item.title}`} sx={sxGoalGrid}>
                        <Box sx={index == 0 ? StyledCardIndex : StyledCard}>
                          <HDCard sx={startedCardStyle}>
                            <Box>
                              <CardTitle variant='h5'>{item.title}</CardTitle>
                              <CardParagraph variant='h5'>{item.description}</CardParagraph>
                            </Box>
                          </HDCard>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          </Container>
          {isMobile ?
            <RenderCarousel ParagraphText={ParagraphText} isMobile={isMobile} isTablet={isTablet} originHost={originHost}/>
            :
            <Container maxWidth='lg'>
              <RenderCarousel ParagraphText={ParagraphText} isMobile={isMobile} isTablet={isTablet} originHost={originHost}/>
            </Container>
          }
          <Container maxWidth='lg'>
            <Box sx={cardsWrapper}>
              <Box sx={bodyContentWrapper}>
                {(!isMobile && !isTablet) &&
                  <Box>
                    <img src={originHost + '/res/coachMobImg.png'} />
                  </Box>
                }
                <Box sx={sliderContentWrapper}>
                  <Box>
                    <CardsTitle variant='h2'>Turn self-awareness into action</CardsTitle>
                    <ParagraphText sx={paragraphTextStyle} variant='h5'>With our flexible monthly coaching subscription, your coach is keeping you accountable until you reach your objectives.</ParagraphText>
                    <Divider sx={dividerStyle} />
                  </Box>
                  <Box>
                    <List>
                      {listContent.map((item: any, index: any) => {
                        return (
                          <ListItem sx={listItemStyle} key={`list-${item.title}`}>
                            <Box sx={{ display: '-webkit-box' }}>
                              <ListCount>{index + 1}</ListCount>
                              <ListItemText
                                sx={{ width: ResponsiveMobileValue(isMobile,'75%' ,'86%') }}
                                primary={item.title}
                                primaryTypographyProps={listTitleStyle}
                                secondary={item.description}
                                secondaryTypographyProps={listDescStyle}
                              />
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                  <Divider sx={{ borderColor: 'rgba(100, 106, 124, 0.1)', margin: '20px 0' }} />
                  <ParagraphText sx={{ fontSize: '14px', marginLeft: '90px' }}>*or reengage coach recommendations to find better alignment. 2-time limit to reengage coaches at the initial stage.</ParagraphText>
                </Box>
                {(isMobile && isTablet) &&
                  <Box sx={{ width: '100%' }}>
                    <img src={originHost + '/res/coachMobImg.png'} style={{ width: '100%' }} />
                  </Box>
                }
              </Box>
            </Box>
          </Container>
        </StyledMainWrapper>

        <Box sx={footerWrapper} ref={bottomSection}>
          <Container maxWidth='lg'>
            <Box sx={footerInnerWrap}>
              <Typography sx={styledTitle}>Our monthly coaching subscription includes:</Typography>
              <Box sx={footerCardStyle}>
                {footerData.map((item: any, index: any) => {
                  return (
                    <Box key={`footer-${item.title}`} sx={{ marginBottom: isMobile ? (index === 2 && '0') || '40px' : '0', flex: '1', marginRight: '10px' }}>
                      <CardTitle sx={{ color: '#fff' }}>{item.title}</CardTitle>
                      <CardParagraph sx={{ color: '#fff' }}>{item.description}</CardParagraph>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box sx={{ textAlign: 'left' }}>
              <CardTitle variant='h2' sx={{ color: '#fff' }}>Choose your program:</CardTitle>
              <Box sx={footerPlans}>
                <Box sx={{ display: 'flex', flexDirection: ResponsiveMobileValue(isMobile,'column' , 'row') }}>
                  {chemistrySessionCompleted ?
                    (
                      <>
                        <Box sx={footerPricingBox}>
                          <Box sx={footerPricingContent}>
                            <Typography sx={styledTitle}>Essential</Typography>
                            <CardTitle sx={{ color: '#fff' }}>1 session per month</CardTitle>
                            <CardParagraph sx={{ color: '#66FCFC' }}>$500/month*</CardParagraph>
                          </Box>
                          <Box sx={footerPricingAction}>
                            <HDButton
                              text={'Select plan'}
                              variant={'contained'}
                              size={'medium'}
                              sx={{ ...btnStyle, marginTop: ResponsiveMobileValue(isMobile,'10px' , '0px'), padding: '10px 20px' }}
                              endIcon={<ArrowIcon />}
                              onClick={() => subscribeNow(1)}
                            />
                          </Box>
                        </Box>

                        <Box sx={footerPricingBox}>
                          <Box sx={footerPricingContent}>
                            <Typography sx={styledTitle}>Standard</Typography>
                            <CardTitle sx={{ color: '#fff' }}>2 sessions per month</CardTitle>
                            <CardParagraph sx={{ color: '#66FCFC' }}>$900/month* <span style={{ fontSize: '14px' }}>(save $100)</span></CardParagraph>
                          </Box>
                          <Box sx={footerPricingAction}>
                            <HDButton
                              text={'Select plan'}
                              variant={'contained'}
                              size={'medium'}
                              sx={{ ...btnStyle, marginTop: ResponsiveMobileValue(isMobile,'10px' , '0px'), padding: '10px 20px' }}
                              endIcon={<ArrowIcon />}
                              onClick={() => subscribeNow(2)}
                            />
                          </Box>
                        </Box>
                      </>
                    ) :
                    (
                      <>
                        <Box sx={footerBox}>
                          <Typography sx={styledTitle}>Essential</Typography>
                          <CardTitle sx={{ color: '#fff' }}>1 session per month</CardTitle>
                          <CardParagraph sx={{ color: '#66FCFC' }}>$500/month*</CardParagraph>
                        </Box>

                        <Box sx={footerBox}>
                          <Typography sx={styledTitle}>Standard</Typography>
                          <CardTitle sx={{ color: '#fff' }}>2 sessions per month</CardTitle>
                          <CardParagraph sx={{ color: '#66FCFC' }}>$900/month* <span style={{ fontSize: '14px' }}>(save $100)</span></CardParagraph>
                        </Box>
                      </>
                    )
                  }
                </Box>
                {(!chemistrySessionCompleted && !isTablet && !isMobile && !isLarge) && (
                  <Box sx={getStartedWrap}>
                    <Typography sx={styledTitle}>Get started</Typography>
                    <HDButton
                      showAnimation={true}
                      text={(chemistrySessionCompleted) ? 'Subscribe Now' : 'Schedule your free intro call'}
                      variant={'contained'}
                      size={'medium'}
                      sx={{ ...btnStyle, marginTop: '20px' }}
                      endIcon={<ArrowIcon />}
                      onClick={buttonAction}
                    />
                  </Box>
                )}
              </Box>
              {(!isTablet && !isMobile && !isLarge) && (
                <Box sx={getStartedWrapper}>
                  <Box sx={{ display: (chemistrySessionCompleted) ? 'none' : 'block' }}>
                    <StyledParagraph sx={{ fontSize: '16px', lineHeight: '22px' }}>
                      Questions about the program?<br />
                      <Link
                        underline='always'
                        color='inherit'
                        href='mailto:heidrickforleaders@heidrick.com'
                      >
                        Send us an email
                      </Link>
                    </StyledParagraph>
                  </Box>
                </Box>
              )}
            </Box>
          </Container>

          <Box sx={getStartedNote}>
            {(!chemistrySessionCompleted) && (
              <Box sx={bottomImage}>
                {(isTablet || isLarge) && (
                  <Container maxWidth='lg' sx={{ position: 'absolute', bottom: '10%' }}>
                    <Box sx={getStartedWrap}>
                      <Typography sx={styledTitle}>Get started</Typography>
                      <HDButton
                        showAnimation={true}
                        text={(chemistrySessionCompleted) ? 'Subscribe Now' : 'Schedule your free intro call'}
                        variant={'contained'}
                        size={'medium'}
                        sx={{ ...btnStyle, marginTop: '20px', width: 'fit-content' }}
                        endIcon={<ArrowIcon />}
                        onClick={buttonAction}
                      />
                    </Box>
                    <Box sx={{ ...getStartedWrapper, justifyContent: 'flex-start' }}>
                      <Box sx={{ display: (chemistrySessionCompleted) ? 'none' : 'block' }}>
                        <StyledParagraph sx={{ fontSize: '16px', lineHeight: '22px' }}>
                          Questions about the program?<br />
                          <Link
                            underline='always'
                            color='inherit'
                            href='mailto:heidrickforleaders@heidrick.com'
                          >
                            Send us an email
                          </Link>
                        </StyledParagraph>
                      </Box>
                    </Box>
                  </Container>
                )}
              </Box>
            )}
            <Container maxWidth='lg'>
              <CardTitle sx={{ color: '#002136', fontSize: '20px', padding: (isTablet || isLarge) ? '32px 0' : 0, width: (isTablet || isLarge) ? '100%' : '52%' }}>
                * You may be eligible for employer reimbursement.&nbsp;
                {(isTablet) && (
                  <br />
                )}
                <Link
                  underline='always'
                  color='inherit'
                  href={`${window.location.origin}/res/reimbursement_learn_more.pdf`}
                  target='_blank'
                >Learn more</Link>
              </CardTitle>
            </Container>
          </Box>
        </Box >

        <Box sx={{ backgroundColor: '#334255' }}>
          <Container maxWidth='lg'>
            <Box sx={{ padding: '32px 0', display: 'flex' }}>
              <Link>
                <img alt='Info logo' src={originHost + '/res/infoDark.svg'} />
              </Link>
              <CardParagraph sx={{ color: '#fff', fontSize: '18px', textAlign: 'left', marginLeft: '10px' }}>
                Coaching services offered through Heidrick for Leaders have no affiliation with Heidrick’s executive search business. Executive coaches do not have access to executive search consultants and joining the program does not grant access to any recruitment processes.
              </CardParagraph>
            </Box>
            <Divider sx={{ borderColor: 'rgba(100, 106, 124, 0.2)' }} />
          </Container>
          <FindCoach
            selectedCoachItem={selectedCoachItem}
            availableDates={availableDates}
            availabilityAllData={availabilityAllData}
            handleFSModal={handleFSModal}
            openFSModal={openFSModal}
            step={step}
            setStep={setStep}
            loading={loading}
            loadingMsg={loadingMsg}
            tabsDataCoach={tabsDataCoach}
            allCoachData={allCoachData}
            coachModalRef={coachModalRef}
            errorMsg={errorMsg}
          />
          <TimeZoneSelectionModal handleFSModalOpen={handleFSModalOpen} accountDetails={globalState?.account} openTzModal={openTzModal} handleTzModalClose={handleTzModalClose} />
          <HDModal
            sx={{ '& .MuiDialog-paper': { maxWidth: ResponsiveMobileValue(isMobile,'100%' , '60%') } }}
            open={assessmentNotTaken}
            title={'Please complete your assessments to get matched with the right coach before booking your free session'}
            description={'Your assessment results will help us recommend executive coaches who have a track record of helping leaders with similar profiles advance their careers.'}
            handleModal={() => { setAssessmentNotTaken(false); }}
            actions={
              <HDButton
                text={'Go to assessments'}
                variant={'contained'}
                size={'medium'}
                showAnimation={true}
                endIcon={<ArrowForwardIcon sx={{ color: isDarkTheme ? '#18768C' : '#66FCFC' }} />}
                onClick={() => {
                  setAssessmentNotTaken(false);
                  navigate('/home', {
                    state: { openrolemodal: true },
                  });
                }}
              />
            }
          />
          <HDModal
            sx={{ '& .MuiDialog-paper': { maxWidth: ResponsiveMobileValue(isMobile, '100%' , '60%') } }}
            open={openAcknowledgeModal}
            title={'Please read and acknowledge'}
            description={'By clicking on the button, you acknowledge that you are about to schedule a session with a certified executive coach who is not affiliated with Heidrick Executive Search\'s business. Executive coaches do not have access to the ongoing hiring processes for Heidrick\'s clients, and are not able to connect you to the Executive Search consultants.'}
            handleModal={() => { setOpenAcknowledgeModal(false); }}
            error={errorText ? errorText : ''}
            actions={
              <HDButton
                text={'I agree'}
                variant={'contained'}
                size={'medium'}
                showAnimation={true}
                onClick={() => {
                  acknowledgeCoach();
                }}
                endIcon={(isAgree) ? <HDSpinner size={'la-sm'} /> : <ArrowForwardIcon sx={{ color: isDarkTheme ? '#18768C' : '#66FCFC' }} />}
                disabled={isAgree}
              />
            }
          />
          {openNofityModal && <FreeSessionNotifyModal setOpenNofityModal={setOpenNofityModal} openNotifyModal={openNofityModal} />}
        </Box>
      </Box >
      <Footer />
    </>
  );
}

export default CoachPage;