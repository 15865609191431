import { Box, Card, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import HDButton from '../../hd-ui-kit/HDButton/index';

export interface HDCoachProfileCardProps {
  title?: string;
  lastName?: string;
  profileImg?: any;
  handleClick?: any;
  profileBGDark?: any;
  profileBGMobile?: any;
  showAnimation?: boolean;
  StyledBtnSx?: any;
  variant?: any;
  fromCoach?: any;
  showButton?: any;
}

const RescheduleCard = (props: HDCoachProfileCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkTheme = useTheme().palette.mode === 'dark';

  const cardStyle = {
    background: isDarkTheme ? 'linear-gradient(234.38deg, #002136 1.23%, #002136 26.94%, rgba(0, 33, 54, 0.3) 90.17%) , #0B0C10 !important' : 'linear-gradient(268.81deg, rgba(25, 126, 149, 0.1) 0.66%, rgba(248, 248, 248, 0) 98.91%), #F8F8F8',
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '250px',
    padding: 0,
  };

  const getButtonWidth = (isMobile:any, showButton:any) => {
    if (isMobile) {
      return showButton ? '390px' : '325px';
    }
    return 'auto';
  };

  const cardStyleMobile = {
    background: isDarkTheme ? 'linear-gradient(234.38deg, #002136 1.23%, #002136 26.94%, rgba(0, 33, 54, 0.3) 90.17%), #0B0C10 !important' : 'linear-gradient(268.81deg, rgba(25, 126, 149, 0.1) 0.66%, rgba(248, 248, 248, 0) 98.91%), #F8F8F8',
    padding: 0,
    borderRadius: '8px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: getButtonWidth(isMobile, props.showButton),
    border: isDarkTheme ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(100, 106, 124, 0.1)',
  };

  const BodyWrap = styled(Box)(() => ({
    //padding: isMobile ? '32px' : isTablet ? '45px' : '64px',
    color: '#fff',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: isMobile ? '100%' : '43%',
    paddingTop: isMobile ? '40px' : '',
    paddingBottom: isMobile ? '31px' : '',
    paddingLeft: '32px',
  }));

  const BodyWrapImg = styled(Box)(() => ({
    background: 'url(' + (isMobile ? props.profileBGMobile : props.profileBGDark) + ') no-repeat bottom right',
    backgroundSize: isMobile ? 'contain' : 'auto',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    backgroundPosition: 'right',
    width: isMobile ? '100%' : '57%',
    height: isMobile ? '210px' : 'auto',
  }));

  const TitleTypoGraphy = styled(Typography)(() => ({
    fontFamily: 'Aventa',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: isMobile ? '31px' : '22px',
    lineHeight: isMobile ? '41px' : '31.57px',
    color: isDarkTheme ? '#FFFFFF' : '#002136',
  }));

  const ArrowForwardSharpIconStyled = styled(ArrowForwardSharpIcon)(() => ({
    color: !isDarkTheme ? '#66FCFC' : '#18768C',
  }));

  const StyledBtn = {
    marginTop: '20px',
    marginBottom: isMobile ? '0' : '20px',
    backgroundColor: !isDarkTheme ? '#334255' : '#F1F1F1',
    fontFamily: 'Aventa',
    fontSize: '15px',
    fontWeight: 600,
    color: !isDarkTheme ? '#FFFFFF' : '#002136',
    '&:focus': {
      background: '#18768C',
    },
  };


  const styledBoxWrap = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };

  const bannerImageStyle = {
    backgroundImage: `url(${props.profileImg})`,
    backgroundRepeat: 'no-repeat',
    height: isMobile ? '180px' : '250px',
    width: isMobile ? '180px' : '250px',
    backgroundSize: 'contain',
    borderRadius: '100%',
    right: isMobile ? '20px' : '50px',
    position: 'relative',
    bottom: isMobile ? '-20%' : '12%',
  };

  return (
    <Card sx={isMobile ? cardStyleMobile : cardStyle}>
      <Box sx={isMobile ? {} : styledBoxWrap}>
        <BodyWrap>
          <Box>
            <TitleTypoGraphy variant="h2">{props.title}</TitleTypoGraphy>
          </Box>
          <Box>{props?.showButton && <HDButton showAnimation={props.showAnimation} variant={'contained'} sx={props.fromCoach ? { marginTop: '13px' } : StyledBtn} endIcon={<ArrowForwardSharpIconStyled />} text={'Schedule Session'} onClick={(e) => props.handleClick(e)} />}</Box>
        </BodyWrap>
        <BodyWrapImg>
          <Box sx={bannerImageStyle}>&nbsp;</Box>
        </BodyWrapImg>
      </Box>
    </Card>
  );
};

export default RescheduleCard;
